import { HookFormCheckbox } from '@finn/auto-ui/components/FormElements/Checkbox/HookFormCheckbox';
import { HookFormTextarea } from '@finn/auto-ui/components/FormElements/HookFormTextarea';
import { Button } from '@finn/design-system/atoms/button';
import { HookFormPhoneInput } from '@finn/platform-modules';
import { HookFormInput } from '@finn/ui-components';
import { JobAutoFormTypeCosmicData, UIBaseCosmicObject } from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { getInputPlaceholder } from '~/utils/cosmic';

type JobAutoType = UIBaseCosmicObject<JobAutoFormTypeCosmicData>;

type Props = {
  form: UseFormReturn<any>;
  loading: boolean;
  cosmicData: JobAutoType;
};
const Form = ({ form, loading, cosmicData }: Props) => {
  const [showGdpr, setShowGdpr] = useState(false);
  const GDPR = cosmicData.metadata.input_fields.gdpr;

  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdpr(!showGdpr);
  };

  return (
    <div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="sm:col-start-1 sm:col-end-3">
          <HookFormInput
            form={form}
            name="company"
            label={getInputPlaceholder(cosmicData, 'company')}
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name="firstName"
            label={getInputPlaceholder(cosmicData, 'first_name')}
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name="lastName"
            label={getInputPlaceholder(cosmicData, 'last_name')}
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicData, 'email_address')}
          />
        </div>
        <div>
          <HookFormPhoneInput
            form={form}
            name="phoneNumber"
            label={getInputPlaceholder(cosmicData, 'phone_number')}
            placeholder={cosmicData.metadata.input_fields.phone_number_format}
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name="numberOfEmployees"
            label={getInputPlaceholder(cosmicData, 'number_of_employees')}
            type="number"
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name="fleetSize"
            label={getInputPlaceholder(cosmicData, 'fleet_size')}
            type="number"
          />
        </div>

        <div className="sm:col-start-1 sm:col-end-3">
          <HookFormTextarea
            form={form}
            name="message"
            placeholder={getInputPlaceholder(cosmicData, 'message')}
          />
        </div>
        <div className="-mt-2 sm:col-start-1 sm:col-end-3">
          <HookFormCheckbox form={form} name="gdpr">
            {GDPR.title}
          </HookFormCheckbox>
          <div className="[&_p]:body-14-regular ml-6 [&_p]:pt-1">
            <Button variant="action" size="md" onClick={handleGDPRToggle}>
              {showGdpr ? GDPR.hide_details : GDPR.show_details}
            </Button>
            {showGdpr && (
              <>
                <br />
                <br />
                <div className="body-14-regular">
                  {parseToHtml(GDPR.gdpr_note)}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mb-4 sm:col-start-1 sm:col-end-3 sm:mb-6">
          <Button type="submit" loading={loading}>
            {cosmicData.metadata.submit_button.button_label}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Form;
