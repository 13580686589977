import { JobAutoFormCosmicMetadata } from '@finn/ui-cosmic';
import { cn, parseToHtml } from '@finn/ui-utils';
import { ElementType } from 'react';

import { titleHeading } from '../B2BContactForm/B2BContactForm';
import { FormWrapper } from './FormWrapper';

type Props = {
  data: JobAutoFormCosmicMetadata;
};

export const JobAutoContactForm = ({ data }: Props) => {
  const titleClassName = titleHeading[data.header_size?.key] || titleHeading.h2;
  const TitleEl = (data.header_size?.key || 'h2') as ElementType;

  return (
    <div className="container">
      <div
        id="jobauto-contact-form"
        className="grid grid-cols-1 gap-6 md:grid-cols-2"
      >
        <div>
          <TitleEl className={cn(titleClassName, 'mb-14')}>
            {parseToHtml(data.title)}
          </TitleEl>
          <div className="body-16-light">{parseToHtml(data.description)}</div>
        </div>
        <FormWrapper cosmicData={data.form_type} htmlId={data.html_id} />
      </div>
    </div>
  );
};

export default JobAutoContactForm;
